.footer {
  padding: 70px 0 35px;
  .auto__container {
    max-width: 1200px;
  }
  &__inner {
    &-row {
      display: flex;
      align-items: flex-start;
      padding: 40px 0 50px;
    }
    &-top {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      &::before {
        position: absolute;
        content: "";
        top: 30px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #a7a7b3;
      }
    }
    &-logo {
      width: 120px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $white;
      margin: 0 auto 12px;
      position: relative;
      z-index: 2;
      img {
        width: 50%;
      }
    }
    &-col {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &.social {
        a {
            margin-bottom: 10px;
        }
      }
      a {
        font-size: 16px;
        line-height: 100%;
        color: $main;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        &:hover {
            opacity: 1;
            color: $primary-dark;
            svg {
                color: $primary-dark;
            }
        }
        &:last-child {
            margin: 0;
        }
        &:nth-child(n + 6) {
            display: none;
        }
        span {
            width: 16px;
            height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 8px 5px;
            svg {
                width: 100%;
                height: 100%;
                color: $main;
                transition: .3s ease;
            }
        }
      }
    }
    &-main {
      width: calc(40% - 16px);
      margin-right: 16px;
      h6 {
        font-size: 20px;
        line-height: 100%;
        margin-bottom: 4px;
      }
      p {
        margin-bottom: 20px;
        max-width: 280px;
      }
      .input {
        margin-bottom: 20px;
        max-width: 280px;
        input {
          padding: 8px 18px 3px;
          background-color: transparent;
          border-radius: 15px;
          font-size: 14px;
          line-height: 100%;
          border: 1px solid #a7a7b3;
          color: $main;
          &::placeholder {
            color: #a7a7b3;
          }
        }
      }
      .button {
        font-size: 14px;
        border-radius: 10px;
        padding: 9px 16px 5px;
        line-height: 100%;
      }
    }
    &-copy {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        &-links {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            a {
                font-size: 12px;
                line-height: 100%;
                color: $primary-dark;
                text-transform: uppercase;
                letter-spacing: .1em;
            }
            hr {
                width: 1px;
                height: 16px;
                background-color: $primary-dark;
                margin: 0 6px 5px;
            }
        }
    }
    h6 {
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      text-transform: capitalize;
    }
  }
}
// 1180px
@mixin footer-xlg-xxlg {
    .footer {
    }
  }
  // 1024px
@mixin footer-lg-xlg {
    .footer {
        padding: 45px 0 24px;
        &__inner{
            &-top {
                &::before {
                    top: 23px;
                }
            }
            &-logo {
                width: 92px;
                height: 46px;
            }
            &-row {
                padding: 35px 0;
            }
        }
    }
  }
// 750px
@mixin footer-sm-md {
  .footer {
    padding: 30px 0 20px;
    &__inner {
        &-row {
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 24px 0 20px;
        }
        &-col {
            &:nth-child(2) {
                display: none;
            }
            a {
                &:nth-child(n) {
                    display: flex;
                }
            }
        }
        &-main {
            width: calc(60% - 24px);
            margin: 0 0 0 24px;
            order: 2;
            max-width: 280px;
            p {
                margin-bottom: 10px;
            }
        }
    }
  }
}
// 650px
@mixin footer-sm-md-extra {
  .footer {
    &__inner {
        &-row {
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 24px 0 40px;
        }
        &-col {
            width: calc(50% - 15px);
 
        }
        &-main {
            width: 100%;
            margin: 30px 0 0 0;
            order: 2;
            max-width: unset;
            p {
                max-width: unset;
            }
            .input {
                max-width: unset;
            }
            .button {
                width: 100%;
            }
        }
    }
  }
}
// 540px
@mixin footer-xsm-sm {
  .footer {
  }
}
// 390px
@mixin footer-xxxsm-xxsm {
  .footer {
  }
}
