.events-wrapper {
  .requestForm {
    padding: 55px 63px 32px;
    margin-bottom: 20px;

    &.empty {
      background-color: unset;
      padding: 0;
      padding-top: 20px;
      display: flex;
    }

    &.one {
    }
    &.two {
    }
    &.three {
    }
    &.four {
    }
    &.submit {
      background-color: unset;
      padding: 0;
      padding-top: 20px;
      display: flex;
      justify-content: center;

      .submit-wrapper {
        width: fit-content;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .submit-btn {
          margin: auto;
          width: 120px;
        }
      }
    }
  }

  .subheading-text {
    margin-bottom: 18px;
    // color: #000000;
  }

  .submit-success-msg {
    font-size: 17px;
    line-height: 19px;
    font-weight: 400;
    color: #000;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }

  .req-item .req-item-upper .req-item-img {
    width: 30% !important;
  }
}
