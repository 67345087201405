@import "../1-main/variables.scss";
@import "../1-main/mixin.scss";

h1 {
  font-weight: 600;
  font-size: 74px;
  line-height: 74px;
}

h2 {
  font-size: 66px;
  line-height: 71px;
  font-weight: 600;
}

h3 {
  font-size: 42px;
  line-height: 50px;
  font-weight: 500;
}

h4 {
  font-size: 26px;
  line-height: 31px;
  font-weight: 500;
  &.big {
    font-size: 30px;
    line-height: 100%;
  }
}

h5 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
}

h6 {
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  text-transform: uppercase;
}
p {
  &.big {
    font-size: 18px;
    line-height: 27px;
  }
  &.sm {
    font-size: 14px;
    line-height: 17px;
    font-weight: 300;
  }
}
.main {
  overflow: hidden;
  padding-top: 77px;
  &.removeP {
    padding: 0;
  }
}
.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  font-family: $mainFont;
  color: $main;
  padding: 12px 26px 6px;
  background-color: $primary;
  cursor: pointer;
  transition: 0.3s ease;
  border: 2px solid transparent;
  &:not(.disabled):hover {
    border-color: #002b49;
    background-color: #002b49;
    color: $white;
  }
}
.check {
  display: flex;
  align-items: center;
  color: $white;
  font-size: 24px;
  line-height: 100%;
  &__box {
    width: 22px;
    height: 22px;
    min-width: 22px;
    min-height: 22px;
    border-radius: 2px;
    position: relative;
    margin: 0 14px 9px 0;
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      opacity: 0;
      &:checked ~ span {
        background-color: $primary;
        img {
          opacity: 1;
        }
      }
    }
    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      border-radius: 2px;
      transition: 0.3s ease;
      border: 2px solid $primary;
      img {
        width: 12px;
        opacity: 0;
        transition: 0.3s ease;
      }
    }
  }
  b {
    width: calc(100% - 36px);
    font-weight: 400;
  }
}
.input {
  display: flex;
  justify-content: center;
  align-items: center;
  &__outer {
    h5 {
      font-size: 17px;
      line-height: 19px;
      font-weight: 400;
      margin-bottom: 6px;
    }
  }
  input {
    width: 100%;
    background-color: #f4f7fb;
    padding: 17px 15px 14px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    font-family: $mainFont;
  }
  textarea {
    width: 100%;
    background-color: #f4f7fb;
    padding: 17px 15px 14px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    font-family: $mainFont;
    resize: unset;
  }
}
@keyframes loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes resize {
  0% {
    transform: matrix(1, 0, 0, 1, 289.5450134277344, 167.40899658203125);
  }
  100% {
    transform: matrix(1, 0, 0, 1, 300.40899658203125);
  }
}
.modall {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #00000048;
  z-index: 12;
  display: flex;
  &Close {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 3px 6px #00000036;
    z-index: 15;
    background-color: $primary-dark;
    &:hover {
      background-color: #33daff;
    }
    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50%;
      height: 3px;
      background-color: $main;
      transform: translate(-50%, -50%) rotate(45deg);
      content: "";
      transition: 0.3s ease;
    }
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50%;
      height: 3px;
      background-color: $main;
      transform: translate(-50%, -50%) rotate(-45deg);
      content: "";
      transition: 0.3s ease;
    }
  }
  &__inner {
    margin: auto;
    background-color: $main;
    color: $white;
    padding: 78px 20px 30px;
    font-weight: 500;
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 540px;
    width: 100%;
    .input {
      &__outer {
        margin-bottom: 20px;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

// misc stuff
.img-fluid {
  max-width: 100%;
  height: auto;
}
.btn-std {
  width: fit-content;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  &:hover {
    background: transparent;
    border-color: #00a6cb;
    color: #00a6cb;
  }
}
